import React, { Component } from 'react';
import ModalMessage from './../../modal-message/ModalMessage';

class ChangeKitchen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            email: '',
            phone: '',
            firstAccept: false,
            secondAccept: false,
            visibleModal: false,
            message: '',
        }
    }

    handleName(event) {
        this.setState({name: event.target.value});
    }

    handleEmail(event) {
        this.setState({email: event.target.value});
    }

    handlePhone(event) {
        this.setState({phone: event.target.value});
    }

    handleFirstAccept() {
        this.setState(prevState => (
            { firstAccept: !prevState.firstAccept }
        ));
    }

    handleSecondAccept() {
        this.setState(prevState => (
            { secondAccept: !prevState.secondAccept }
        ));
    }

    toogleModal() {
        this.setState( prevState => ({
            visibleModal: !prevState.visibleModal
        }))
    }

    submitForm(e) {
        e.preventDefault();
        const data = 'email=' + this.state.email + '&name=' + this.state.name + '&phone=' + this.state.phone + '&zgoda=' + this.state.firstAccept + '&zgoda2=' +  this.state.secondAccept;

        const xhr = new XMLHttpRequest();
        const This= this;

        xhr.open("POST", this.props.home + `/wp-json/contact-form-7/v1/contact-forms/${ this.props.form }/feedback`, true);
        xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
        xhr.send(data);

        xhr.addEventListener('load', function() {
            let data = JSON.parse(xhr.response);
            This.setState({
                visibleModal: true,
                message: data.message,
            });
        });
    }

    render() {

        return (
        <section className="newsletter-wrap">
            <ModalMessage message={ this.state.message } visible={ this.state.visibleModal } closeModal={ this.toogleModal.bind(this) } />
                <div className="container newsletter">
                    <div className="row">
                        <div className="col-lg-6">
                            <h3>Chcesz odmienić
                                swoją kuchnie?</h3>
                            <h4>Wyślij wiadomość! Odpowiemy w przeciągu 24h</h4>
                        </div>
                        <div className="col-lg-6">
                            <form action="#" onSubmit={ this.submitForm.bind(this) }>
                                <label htmlFor="">
                                    <input type="text" name="name" value={ this.state.name } onChange={ this.handleName.bind(this) } placeholder="Imię i nazwisko" autoComplete="off" required />
                                </label>
                                <label htmlFor="">
                                    <input type="email" name="email" value={ this.state.email } onChange={ this.handleEmail.bind(this) } id="email" placeholder="Adres email" autoComplete="off" required />
                                </label>
                                <label htmlFor="">
                                    <input type="tel" name="phone" value={ this.state.phone } onChange={ this.handlePhone.bind(this) } id="" placeholder="Telefon" required />
                                </label>
                                <button type="submit">Wyślij</button>
                                <label className="rules" htmlFor="rule1">
                                    <input type="checkbox" onClick={ this.handleFirstAccept.bind(this) } checked={ ( this.state.firstAccept ? true : false ) } id="rule1" required/>
                                    <small>Wyrażam zgodę na przetwarzanie danych osobowych  zgodnie z ustawą o ochronie danych osobowych, w związku z przesłaniem zapytania przez formularz kontaktowy. Podanie danych jest dobrowolne, ale niezbędne do prawidłowego przetworzenia wiadomości. Administratorem danych jest  MS-Meble Małyjurek Spółka z Ograniczoną Odpowiedzialnością z siedzibą przy ulicy Cieszyńskiej 82, 43-400 Cieszyn.</small>
                                </label>
                                <label className="rules" htmlFor="rule2">
                                    <input type="checkbox" onClick={ this.handleSecondAccept.bind(this) } checked={ ( this.state.secondAccept ? true : false ) }  name="" id="rule2" />
                                    <small>Wyrażam zgodę na otrzymywanie newslettera i informacji handlowych od  MS-Meble Małyjurek Spółka z Ograniczoną Odpowiedzialnością z siedzibą przy ulicy Cieszyńskiej 82, 43-400 Cieszyn. 
Zgoda jest dobrowolna. Mam prawo cofnąć zgodę w każdym czasie, mam prawo dostępu do danych, sprostowania, usunięcia lub ograniczenia przetwarzania.. Administratorem jest MS-Meble Małyjurek Spółka z Ograniczoną Odpowiedzialnością z siedzibą przy ulicy Cieszyńskiej 82, 43-400 Cieszyn Administrator przetwarza dane zgodnie z Polityką Prywatności.</small>
                                </label>
                            </form>
                        </div>
                        <div class="col">
                            <hr />
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default ChangeKitchen;