
 import React, { Component } from 'react'

 class ModalMessage extends Component {
    constructor(props) {
        super(props);
    } 
    render() {
        if( this.props.visible ) {
            return (
                <div className="modal-message">
                    <h2>{ this.props.message }</h2>
                    <div className="btn-white" onClick={ this.props.closeModal }>Zamknij</div>
                </div>
             );
        }
        return null;
    }
 }

 export default ModalMessage;