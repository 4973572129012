import React from "react";

function Items(props) {
    return props.props.data.map(item => (
        <div class="col-lg-4 col-md-6">
            <img src={ item.img.url.localFile.publicURL } alt={ item.img.alt } />
            <div class="products__text">
                <h4>{ item.title }</h4>
                <p>{ item.text }</p>
            </div>
        </div>
    ));
}
export default (props) => (
    <div class="container products">
        <div class="row">
            <Items props={props} />
            <div class="col-12">
                <hr />
            </div>
        </div>
    </div>
);