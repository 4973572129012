import React from "react";
import { Link } from 'gatsby';

export default (props) => (
    <>
        <div className="col-12 more-photos">
            <h3>{ props.title }</h3>
            <Link to={ props.link } className="btn">{ props.buttonTitle }</Link>
        </div> 
        <div className="col-12 more-photos">
            <hr />
        </div>
    </>
);