import React, { Component } from "react";
import { graphql } from "gatsby";
import Layout from '../components/layout';
import PortfolioBanner from '../components/portfolio-banner/PortfolioBanner';
import PortfolioAdvantages from '../components/portfolio-advantages/PortfolioAdvantages';
import PortfolioGallery from '../components/portfolio-gallery/PortfolioGallery';
import ChangeKitchen from '../components/forms/change-kitchen/ChangeKitchen';
import SEO from '../components/seo';


class PostTemplate extends Component {
  render() {
    const currentPage = this.props.data.wordpressWpPortfolio;
    const homepage = this.props.data.wordpressSiteMetadata.home;


    return (
      <Layout>
        <SEO title={currentPage.title + ' | Kuchnie na wymiar Śląsk, Małopolska | Meble kuchenne Kraków'} />
        <div className="product">
          <PortfolioBanner data={ this.props.data.wordpressWpPortfolio.acf.banner } title={ this.props.data.wordpressWpPortfolio.acf.title } city={ this.props.data.wordpressWpPortfolio.acf.city } />
          <PortfolioAdvantages data={ this.props.data.wordpressWpPortfolio.acf.advantages_repeat } />
          <PortfolioGallery data={ this.props.data.wordpressWpPortfolio.acf.gallery_repeat } />
          <ChangeKitchen form={ this.props.data.wordpressWpPortfolio.acf.contactCode } home={homepage} />
        </div>
      </Layout>
    )
  }
}

export default PostTemplate

export const pageQuery = graphql`
  query($id: String) {
    wordpressWpPortfolio(id: { eq: $id }) {
      title
      acf {
        contactCode
        title
        city
        gallery_repeat {
          gallery_img {
            alt_text
            localFile {
              childImageSharp {
                original {
                  src
                }
                big:fixed(width: 480, height: 770, quality: 95) {
                  src
                }
                medium:fixed(width: 480, height: 505, quality: 95) {
                  src
                }
                small:fixed(width: 480, height: 235, quality: 90) {
                  src
                }
              }
            }
          }
        }
        advantages_repeat {
          text
          title
          img {
            alt
            url {
              source_url
              localFile {
                publicURL
              }
            }  
          }
        }
        banner {
          localFile {
            childImageSharp {
              fluid(maxWidth:1920, maxHeight: 1080, quality: 95) {
                srcSet
              }
            }
          }
        }
      }
      date(formatString: "MMMM DD, YYYY")
    }
    site {
      id
      siteMetadata {
        title
      }
    }
    wordpressSiteMetadata {
      home
    }
  }
`