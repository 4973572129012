import React, { Component } from "react";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; 
import SeeOther from './../see-other/SeeOther';

class PortfolioGallery extends Component {
    constructor(props) {
        super(props);
        this.state = {
            photoIndex: 0,
            isOpen: false,
            images: [],
        }
    }
    
    pushImagesToState(){
        this.props.data.map(item => {
            let image = item.gallery_img.localFile.childImageSharp.original.src;
            this.setState(prevState => ({
                images: [...prevState.images, image]
            }));
        });
    }

    componentDidMount() {
        this.pushImagesToState();
    }

    render() {
        const Gallery = (props) => {
            return props.images.map((item, index) => (
                <li>
                    { ( index == 0 ? <img src={ item.gallery_img.localFile.childImageSharp.big.src } alt={ item.gallery_img.alt_text } onClick={ () => this.setState({ isOpen: true, photoIndex: index }) } /> : '' ) }
                    { ( index == 1 ? <img src={ item.gallery_img.localFile.childImageSharp.medium.src } alt={ item.gallery_img.alt_text } onClick={ () => this.setState({ isOpen: true, photoIndex: index }) } /> : '' ) }
                    { ( index == 2 ? <img src={ item.gallery_img.localFile.childImageSharp.big.src } alt={ item.gallery_img.alt_text } onClick={ () => this.setState({ isOpen: true, photoIndex: index }) } /> : '' ) }
                    { ( index == 3 ? <img src={ item.gallery_img.localFile.childImageSharp.small.src } alt={ item.gallery_img.alt_text } onClick={ () => this.setState({ isOpen: true, photoIndex: index }) } /> : '' ) }
                </li>
            ));
        }
        return (
            <div class="container product-gallery">
                <div class="row">
                    <ul>
                        <Gallery images={this.props.data} />
                    </ul>
                    {this.state.isOpen && (
                        <Lightbox
                        mainSrc={this.state.images[this.state.photoIndex]}
                        nextSrc={this.state.images[(this.state.photoIndex + 1) % this.state.images.length]}
                        prevSrc={this.state.images[(this.state.photoIndex + this.state.images.length - 1) % this.state.images.length]}
                        onCloseRequest={() => this.setState({ isOpen: false })}
                        onMovePrevRequest={() =>
                            this.setState({
                            photoIndex: (this.state.photoIndex + this.state.images.length - 1) % this.state.images.length,
                            })
                        }
                        onMoveNextRequest={() =>
                            this.setState({
                            photoIndex: (this.state.photoIndex + 1) % this.state.images.length,
                            })
                        }
                        />
                    )}
                    <SeeOther title={'Zobacz inne realizacje'} link={'/realizacje'} buttonTitle={'Przejdź do realizacji'} />
                </div>
            </div>
        );
    }
}

export default PortfolioGallery;